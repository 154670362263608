import * as React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../content/button'
import Link from '../shared/link'
import Tag from '../content/tag'
import { H1, P } from '../shared/typography'

const ProviderDetails = ({ title, phone, description, serviceGroup, website, children }) => {
    if (website) {
        if (website.indexOf('http') === -1) {
            website = `http://${website}`
        }
    }

    return (
        <DetailsContainer>
            <ProviderTitle size="h3">{title}</ProviderTitle>
            <ProviderMeta>
                {serviceGroup && (
                    <Tag primary>
                        <CategoryIcon
                            icon={[
                                'far',
                                `${serviceGroup.category.icon.iconName}`,
                            ]}
                            size="l"
                        />
                        {serviceGroup.category.title}
                    </Tag>
                )}
                {children}
                {phone && (
                    <P size="h4">
                        <Link to={`tel:${phone}`}>{phone}</Link>
                    </P>
                )}
                {website && (
                    <P>
                        <Button as="a" href={website}>
                            Visit Website
                        </Button>
                    </P>
                )}
            </ProviderMeta>
            {description && (
                <P>
                    {description}
                </P>
            )}
        </DetailsContainer>
    )
}

const DetailsContainer = styled.div`
    padding: var(--space-s) var(--space-s) 0 var(--space-s);
`

const ProviderTitle = styled(H1)`
    margin: 0 0 var(--space-xs) 0;
`

const ProviderMeta = styled.div`
    display: block;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    justify-content: space-between;
`

const CategoryIcon = styled(FontAwesomeIcon)`
    display: inline-block;
    padding-right: var(--space-3xs);
`

export default ProviderDetails
