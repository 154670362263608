import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
    InstantSearch,
    Configure,
    Pagination,
    connectStateResults,
    connectHits,
    connectHitInsights,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import styled from 'styled-components'
import find from 'lodash/find'

import Layout from '../../components/layout'

import Categories from '../../components/search/categories'
import Divider from '../../components/content/divider'
import * as hitComps from '../../components/search/hit-comps'
import ProviderDetails from '../../components/provider/provider-details'
import ProviderServices from '../../components/provider/provider-services'
import ProviderLocations from '../../components/provider/provider-locations'
import SearchBox from '../../components/search/search-box'

/*
export default function Component(props) {
  return <h1>{props.params.title} // highlight-line</h1>
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.

export const query = graphql`
  query($id: String) {
    product(id: { eq: $id }) {
      fields {
        sku
      }
    }
  }
`*/

export const data = graphql`
    query ProviderById($id: String!) {
        allContentfulPostalCode {
            edges {
                node {
                    postalCode
                    locationGeo {
                        lat
                        lon
                    }
                }
            }
        }
        contentfulServiceProvider(id: { eq: $id }) {
            id
            title
            description {
                description
            }
            website
            primaryPhone
            primaryServiceGroup {
                title
                category {
                    title
                    icon {
                        iconName
                    }
                }
            }
            locations {
                title
                serviceGroups {
                    title
                    category {
                        icon {
                            iconName
                        }
                    }
                }
                addressLine1
                addressLine2
                city
                phone
                state
                postalCode {
                    postalCode
                }
                siteHours {
                    siteHours
                }
            }
        }
    }
`

const ProviderPage = ({ data }) => {
    const provider = data.contentfulServiceProvider
    //aroundLatLng: '40.71, -74.01',
    const [latLng, setLatLng] = useState('')
    const [location, setLocation] = useState(null)
    const [currentPostalCode, setCurrentPostalCode] = useState('')

    const setPostalCode = (postalCode) => {
        const allPostalCodes = data.allContentfulPostalCode.edges
        const postalCodeEntry = find(allPostalCodes, {
            node: {
                postalCode: `${postalCode}`,
            },
        })

        if (typeof postalCodeEntry !== 'undefined') {
            const latLng = `${postalCodeEntry.node.locationGeo.lat}, ${postalCodeEntry.node.locationGeo.lon}`
            setLatLng(latLng)
            setLocation(postalCode)
            setCurrentPostalCode(postalCode)
        } else {
            setLatLng(null)
            setLocation(null)
            setCurrentPostalCode(null)
        }
    }

    const searchIndices = {
        default: {
            name: `prod_Providers`,
            title: `Providers`,
            hitComp: `SearchHit`,
            helpText: 'Search',
        },
        Providers: {
            name: `prod_Providers`,
            title: `Providers`,
            hitComp: `SearchHit`,
            helpText: 'Search',
        },
    }

    const selectedIndex = searchIndices.default

    const searchClient = algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
    )

    const indexName = selectedIndex.name
    const hitComp = selectedIndex.hitComp

    return (
        <Layout>
            <InstantSearch searchClient={searchClient} indexName={indexName}>
                {latLng && (
                    <Configure
                        hitsPerPage={20}
                        clickAnalytics
                        getRankingInfo={true}
                        filters={`postalCodesServiced.postalCodesCovered.postalCode:${currentPostalCode}`}
                    />
                )}
                {!latLng && (
                    <Configure
                        hitsPerPage={20}
                        clickAnalytics
                        aroundLatLngViaIP={true}
                        getRankingInfo={true}
                        aroundRadius={50000}
                    />
                )}
                <section>
                    <SearchBox onPostalCode={setPostalCode} />
                    <Categories />
                </section>
                <Provider>
                    <Results provider={provider}>
                        <div aria-live="polite">
                            <CustomHits hitComp={hitComp} hitsAsGrid={true} postalCode={location} />
                        </div>
                    </Results>
                </Provider>
            </InstantSearch>
        </Layout>
    )
}

const Results = connectStateResults(
    ({ searchState: searchState, searchResults: res, children, provider }) =>
        searchState && searchState.query ? (
            res && res.nbHits > 0 ? (
                children
            ) : (
                <p>Your search did not return any results.</p>
            )
        ) : (
            <>
                <ProviderDetails
                    title={provider.title}
                    phone={provider.primaryPhone}
                    description={provider.description ? provider.description.description : null }
                    serviceGroup={provider.primaryServiceGroup}
                    website={provider.website}
                />
                <Divider />
                <ProviderLocations locations={provider.locations} />
            </>
        )
)

const HitWithInsights =
    typeof window !== 'undefined' ? hitComps['HitComps'] : hitComps['HitComps']

const SearchHits = ({ hits, postalCode }) => {
    return (
        <>
            {hits.map((hit) => (
                <div key={`search-hit-${hit.objectID}`}>
                    <HitWithInsights key={hit.objectID} hit={hit} postalCode={postalCode} />
                </div>
            ))}
        </>
    )
}

const CustomHits = connectHits(SearchHits)

const Provider = styled.article`
    padding: var(--space-m) var(--space-m) 0 var(--space-m);
`

export default ProviderPage
